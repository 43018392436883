<template>
  <vs-row>
    <vs-col
      vs-lg="12"
      vs-sm="12"
    >
      <vs-button
        :disabled="!signToParticipate"
        type="border"
        @click="advancedPopup = true"
      >
        <i class="fa fa-cog mr-2" />

        <u>Configurações Avançadas</u>
      </vs-button>
    </vs-col>

    <!-- Advanced Settings Popup -->
    <vs-popup
      class="prize-survey--advanced-popup"
      fullscreen
      title="Configurações Avançadas"
      :active.sync="advancedPopup"
    >
      <form class="d-flex flex-column justify-content-between h-100">
        <vs-row>
          <vs-col
            vs-lg="6"
            vs-sm="6"
          >
            <vs-row>
              <!-- Eligible Participants -->
              <vs-col
                vs-lg="12"
                vs-sm="12"
                class="d-flex flex-column"
              >
                <vs-divider position="left">
                  <h5>Condições de Participação</h5>
                </vs-divider>

                <vs-card class="c-fieldset">
                  <label class="c-fieldset--label">
                    Quem pode participar desse sorteio?
                  </label>

                  <vs-radio
                    v-model="eligibleParticipants"
                    vs-name="eligible-participants"
                    vs-value="any"
                    color="secondary"
                  >
                    Qualquer pessoa
                  </vs-radio>

                  <vs-radio
                    v-model="eligibleParticipants"
                    vs-name="eligible-participants"
                    vs-value="subscriber"
                    color="secondary"
                  >
                    Somente sócios dos planos selecionados:
                  </vs-radio>
                  <div
                    v-if="eligibleParticipants === 'subscriber'"
                    class="option-extended"
                  >
                    <vs-button
                      type="flat"
                      @click="membershipsPopup = true"
                    >
                      Selecionar Planos
                    </vs-button>

                    <vs-chip
                      v-if="eligiblePlans.length === 0"
                      color="dark"
                      transparent
                    >
                      Nenhum plano selecionado
                    </vs-chip>
                    <div
                      v-else
                      class="d-flex"
                    >
                      <vs-chip
                        v-for="plan in eligiblePlans"
                        :key="plan"
                        color="primary"
                        class="text-center"
                      >
                        {{ plan }}
                      </vs-chip>
                    </div>
                  </div>

                  <vs-radio
                    v-model="eligibleParticipants"
                    vs-name="eligible-participants"
                    vs-value="compliant-subscriber"
                    color="secondary"
                  >
                    Somente sócios adimplente dos planos selecionados:
                  </vs-radio>
                  <div
                    v-if="eligibleParticipants === 'compliant-subscriber'"
                    class="option-extended"
                  >
                    <vs-button
                      type="flat"
                      @click="membershipsPopup = true"
                    >
                      Selecionar Planos
                    </vs-button>

                    <vs-chip
                      v-if="eligiblePlans.length === 0"
                      color="dark"
                      transparent
                    >
                      Nenhum plano selecionado
                    </vs-chip>
                    <div
                      v-else
                      class="d-flex"
                    >
                      <vs-chip
                        v-for="plan in eligiblePlans"
                        :key="plan"
                        color="primary"
                        class="text-center"
                      >
                        {{ plan }}
                      </vs-chip>
                    </div>
                  </div>
                </vs-card>
              </vs-col>
            </vs-row>
          </vs-col>

          <!-- Survey Params -->
          <vs-col
            vs-lg="6"
            vs-sm="6"
            class="d-flex flex-column"
          >
            <vs-divider position="left">
              <h5>Parâmetros da Pesquisa</h5>
            </vs-divider>

            <vs-card>
              <p>Todos os parâmetros:</p>

              <div :style="{ maxHeight: '450px', overflowY: 'auto' }">
                <ul class="list-group c-list-group">
                  <li
                    v-for="param in surveyParams"
                    :key="param"
                    class="list-group-item d-flex align-items-center justify-content-between"
                  >
                    <span>{{ param }}</span>

                    <vs-input
                      v-model="updatedParams[param]"
                      placeholder="Valor"
                    />
                  </li>
                </ul>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </form>

      <!-- Popups -->
      <!-- Select Membership Plans -->
      <vs-popup
        title="Selecionar Planos"
        :active.sync="membershipsPopup"
        background-color="rgba(0, 0, 0, 0.17)"
      >
        <div :style="{ maxHeight: '450px', overflowY: 'auto' }">
          <vs-checkbox
            v-model="selectAllPlans"
            class="pb-3 pl-2"
            color="secondary"
            style="width:fit-content;"
          >
            Selecionar Todos
          </vs-checkbox>

          <ul class="list-group c-list-group">
            <li
              v-for="mp in membershipPlans"
              :key="mp.plan"
              class="list-group-item d-flex align-items-center justify-content-between"
            >
              <vs-checkbox
                v-model="eligiblePlans"
                vs-name="eligible-plans"
                :vs-value="mp.plan"
                color="secondary"
                :disabled="selectAllPlans"
              >
                <h6 class="ml-1">
                  {{ mp.plan }}
                </h6>
              </vs-checkbox>
            </li>
          </ul>
        </div>
      </vs-popup>
    </vs-popup>
  </vs-row>
</template>

<script>
/* Services */
import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

export default {
  name: 'PrizeSurveyParamsForm',
  props: {
    params: {
      type: Object,
      required: true,
    },
    signToParticipate: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    surveyParams: [],
    updatedParams: [],
    membershipPlans: [],
    selectAllPlans: false,
    // Advanced - Eligibility
    eligibleParticipants: 'any', // values: any, subscriber, compliant-subscriber
    eligiblePlans: [],
    /* Popups */
    advancedPopup: false,
    membershipsPopup: false,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    eligibleParticipants(value) {
      let updated = { ...this.updatedParams };

      switch (value) {
      case 'subscriber':
        updated.CHECK_USR_ALWD = 'SYNC_SOCIO';
        break;
      case 'compliant-subscriber':
        updated.CHECK_USR_ALWD = 'SYNC_SOCIO_ADIPLENTE';
        break;
      default:
        delete updated.CHECK_USR_ALWD;
        break;
      }

      this.updatedParams = updated;
    },
    eligiblePlans(value) {
      let updated = { ...this.updatedParams };

      for (let index = 0; index < 11; index += 1) {
        if (index === 0) {
          if (value[0]) {
            updated.CHECK_PLAN_ALWD = value[0];
          } else {
            delete updated.CHECK_PLAN_ALWD;
          }
        } else {
          const propName = `CHECK_PLAN_ALWD_${index}`;

          if (value[index]) {
            updated[propName] = value[index];
          } else {
            delete updated[propName];
          }
        }
      }

      this.updatedParams = updated;
    },
    selectAllPlans(value) {
      this.eligiblePlans = [];

      if (value) {
        this.eligiblePlans = this.membershipPlans.map((mp) => mp.plan);
      }
    },
    updatedParams(value) {
      this.$emit('set-params', value);
    },
  },
  created() {
    this.getSurveyParams();
    this.getMembershipPlans();

    this.updatedParams = this.params;
  },
  methods: {
    /* API */
    getSurveyParams() {
      surveyService.getSurveyParams(this.token)
        .then((resp) => {
          // Filter params by name
          this.surveyParams = resp.filter((p) => p.startsWith('EV_'));
        });
    },
    getMembershipPlans() {
      systemService
        .getMembershipPlansByOwner(this.ownerId, this.token)
        .then((resp) => (this.membershipPlans = resp));
    },
  },
};
</script>

<style lang="scss">
.prize-survey--advanced-popup .vs-popup--content {
  height: 100%;
  padding-bottom: 0;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 20px;
    border: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }
}

.c-fieldset {
  display: flex;
  flex-flow: column nowrap;

  .c-fieldset--label {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .option-extended {
    align-items: center;
    background: #efefef;
    border-radius: 5px;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 10px;
    margin: 0 0 1rem 1.25rem;
    width: fit-content;
    max-width: 90%;
    animation: fade-in 0.5s ease-in-out forwards;
  }

  .con-vs-checkbox,
  .con-vs-radio {
    justify-content: flex-start !important;
  }

  .con-vs-radio .vuesax-app-is-ltr .vs-radio--label {
    margin-left: 10px;
  }
}

.data-range-popup {
  .vs-popup {
    width: 400px;
  }

  .vs-popup--content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
}

/* Custom Dialog */
.con-vs-dialog .vs-dialog {
  .vs-dialog-text {
    color: black;
    font-size: 0.9em;
  }

  & > footer .vs-dialog-cancel-button {
  color: #333 !important;
  }
}

/* Custom File Input */
.file-input {
  display: none;
}

/* Custom List Group */
.c-list-group {
  .list-group-item:first-child {
    border-radius: 0 !important;
  }

  .list-group-item:last-child {
    border-radius: 0 !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
