import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class Prize {
  /* Prize Project CRUD */
  getPrizeProjects(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/getByOwnerId/${ownerId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createPrizeProject(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePrizeProject(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .put(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deletePrizeProject(ownerId, prizeCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/${ownerId}/${prizeCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Prize Draw CRUD */
  getDraws(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/ownerPrizeDraws/${ownerId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getDrawsByPrizeCode(ownerId, prizeCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/draws/${ownerId}/${prizeCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createPrizeDraw(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/draws`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePrizeDraw(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/draws`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .put(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deletePrizeDraw(drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/draws/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Publish */
  publishDraw(drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/publishResult/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, {}, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Winners */
  drawWinners(ownerId, drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/drawWinners/${ownerId}/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, null, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getWinners(ownerId, drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/getWinners/${ownerId}/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  notifyWinners(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/notifyWinners`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      // - Payload:
      // {
      //     "ownerId" : 0,
      //     "drawCode" : "",
      //     "EmailOptions" : {
      //         "subject": "",
      //         "body": "",
      //         "fileId": 0
      //     },
      //     "smsOptions" : {
      //         "Text": "",
      //         "templateCode": "",
      //         "params": {
      //             "key1": "value1"
      //         }
      //     },
      //     "whatsappOptions" : {
      //         "Text": "",
      //         "templateCode": "",
      //         "params": {
      //             "key1": "value1"
      //         }
      //     },
      //     "processType": 0,
      //     "processRef": "",
      //     "systemId": 0
      // }

      axios
        .post(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  exportWinners(ownerId, drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/exportWinners/${ownerId}/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Blacklist */
  clearBlacklist(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/blackList/${ownerId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  uploadBlacklist(files, override, ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/uploadBlackList/${ownerId}`;

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      let formData = new FormData();
      files.forEach((file) => formData.append('file', file));
      formData.append('shouldOverride', JSON.stringify(override));

      axios
        .post(
          url,
          formData,
          config,
        )
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Survey */
  getDrawSurveys(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/drawSurveyURLs/${ownerId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getParticipants(drawCode, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/participants/${drawCode}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteParticipant(userSurveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/prizes/participants/${userSurveyId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
