import moment from 'moment';

const validatePrizeDraw = (draw) => {
  const {
    prizeDate,
    prizeQty,
    prizeTime,
    prizeSignEndDate,
    prizeSignEndTime,
    prizeSignInitDate,
    prizeSignInitTime,
  } = draw;

  const validNumber = (n) => {
    return (typeof n === 'number' && n > 0);
  };

  const validDate = (d) => {
    const date = moment(d, 'DD/MM/YYYY', true);
    return date.isValid();
  };

  const validTime = (t) => {
    const time = moment(t, 'HH:mm', true);
    return time.isValid();
  };

  let registrationValid = true;

  if (draw.signToParticipate) {
    registrationValid = (
      validDate(prizeSignEndDate) &&
      validDate(prizeSignInitDate) &&
      validTime(prizeSignEndTime) &&
      validTime(prizeSignInitTime)
    );
  }

  return (
    validNumber(prizeQty) &&
    validDate(prizeDate) &&
    validTime(prizeTime) &&
    registrationValid
  );
};

export default validatePrizeDraw;
