<template>
  <div>
    <vs-button
      type="border"
      icon="list"
      @click="blacklistPopup = true"
    >
      Lista de Exclusão
    </vs-button>

    <vs-popup
      :active.sync="blacklistPopup"
      title="Lista de Exclusão"
    >
      <vs-card class="c-fieldset">
        <label class="mb-3">
          <strong>Opção de Exclusão:</strong>
        </label>

        <vs-radio
          v-model="restrictionTypeModel"
          vs-name="previous-winners-exclusion"
          :vs-value="0"
          color="secondary"
        >
          Permitir todos
        </vs-radio>

        <vs-radio
          v-model="restrictionTypeModel"
          vs-name="previous-winners-exclusion"
          :vs-value="1"
          color="secondary"
        >
          <div class="d-flex align-items-center">
            <span class="mr-1">
              Não permitir ganhadores anteriores do sorteio base
            </span>

            <vs-tooltip text="Filtrar por data">
              <vs-button
                size="small"
                icon="event"
                type="flat"
                @click="dataRangePopup = true"
              />
            </vs-tooltip>

            <small
              v-if="validLimitDate"
              class="text-muted ml-1"
            >
              {{ restrictionLimitDate }}
            </small>
          </div>
        </vs-radio>

        <vs-radio
          v-model="restrictionTypeModel"
          vs-name="previous-winners-exclusion"
          :vs-value="2"
          color="secondary"
        >
          Não permitir ganhadores anteriores de todos os sorteios
        </vs-radio>

        <div class="d-flex align-items-center mt-4 mb-2">
          <vs-button
            class="ml-1"
            icon="settings"
            type="flat"
            @click.stop="filePopup = true"
          >
            <span :style="{ fontSize: '0.95em', marginLeft: '0.15rem' }">
              Configurar Arquivo
            </span>
          </vs-button>
        </div>

        <!-- Popups -->
        <!-- File Popup -->
        <vs-popup
          title="Configurar Arquivo"
          :active.sync="filePopup"
        >
          <div class="d-flex align-items-start flex-column pt-2 pb-3 pl-3 pr-3">
            <label class="mb-2">
              <strong>Upload de Arquivo:</strong>
            </label>

            <div class="d-flex align-items-center mb-3">
              <div>
                <input
                  id="upload-file-input"
                  class="file-input"
                  type="file"
                  accept=".csv"
                  @change="uploadFile"
                >

                <vs-button icon="file_upload">
                  <label
                    class="m-0 cursor-pointer"
                    for="upload-file-input"
                  >
                    Escolher...
                  </label>
                </vs-button>
              </div>

              <p class="mb-0 ml-3">
                {{ fileName.length > 0 ? fileName : 'Nenhum arquivo selecionado.' }}
              </p>

              <vs-button
                v-if="fileName.length > 0"
                class="ml-1"
                color="dark"
                icon="close"
                type="flat"
                @click="clearFile"
              />
            </div>

            <small>O arquivo .csv deve conter as colunas "CPF" e "Email" (apenas uma é obrigatória).</small>

            <vs-button
              class="mt-4"
              color="danger"
              icon="delete"
              type="border"
              @click="clearBlacklist"
            >
              Limpar Lista de Exclusão
            </vs-button>
          </div>
        </vs-popup>

        <!-- Previous Winners Date Range -->
        <vs-popup
          title="Filtrar por Data"
          :active.sync="dataRangePopup"
          class="data-range-popup"
        >
          <p>Escolha a data limite para os sorteios considerados:</p>

          <div class="d-flex">
            <vs-input
              v-model="restrictionLimitDateModel"
              v-mask="'##/##/####'"
              placeholder="DD/MM/YYYY"
              label="Data Limite"
              class="mr-2"
            />
          </div>

          <vs-alert
            v-if="!validLimitDate"
            color="danger"
            class="mt-2"
          >
            A data informada é inválida.
          </vs-alert>

          <vs-button
            :disabled="!validLimitDate"
            class="mt-3"
            @click="dataRangePopup = false"
          >
            Confirmar
          </vs-button>
        </vs-popup>
      </vs-card>
    </vs-popup>
  </div>
</template>

<script>
/* Directives */
import { mask } from 'vue-the-mask';

/* Helpers */
import validateDate from '../helpers/validate-date';

/* Services */
import Prize from '@/services/prize';

const prizeService = new Prize();

export default {
  name: 'PrizeDrawBlacklist',
  directives: { mask },
  props: {
    restrictionLimitDate: {
      type: String,
      required: true,
    },
    restrictionType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    blacklistFile: [],
    fileName: '',
    /* Popups */
    blacklistPopup: false,
    dataRangePopup: false,
    filePopup: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Form */
    validLimitDate() {
      return validateDate(this.restrictionLimitDate);
    },
    /* Models */
    restrictionLimitDateModel: {
      get() { return this.restrictionLimitDate },
      set(value) { this.$emit('set-restriction-limit-date', value) },
    },
    restrictionTypeModel: {
      get() { return this.restrictionType },
      set(value) { this.$emit('set-restriction-type', value) },
    },
  },
  watch: {
    blacklistFile(value) {
      if (value.length > 0) this.openBlacklistDialog();
    },
  },
  methods: {
    /* API */
    clearBlacklist() {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService
        .clearBlacklist(
          this.ownerId,
          this.token,
        )
        .catch((err) => this.handleError(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    uploadBlacklist(override) {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService
        .uploadBlacklist(
          this.blacklistFile,
          override,
          this.ownerId,
          this.token,
        )
        .catch((err) => this.handleError(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    handleError(err) {
      console.error(err);

      this.$vs.notify({
        title: 'Erro',
        color: 'danger',
        text: err,
      });
    },
    clearFile() {
      this.blacklistFile = [];
      this.fileName = '';
    },
    uploadFile(e) {
      this.blacklistFile = e.target.files;
      this.fileName = e.target.files[0].name;
    },
    /* Popups */
    openBlacklistDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Lista de Exclusão',
        acceptText: 'Apenas Adicionar',
        cancelText: 'Sobrescrever',
        text: 'Você deseja sobrescrever a lista carregada anteriormente ou apenas adicionar?',
        accept: () => this.uploadBlacklist(false),
        cancel: () => this.uploadBlacklist(true),
      });
    },
  },
};
</script>
